import React from 'react';
import ReactDOM from 'react-dom';
import Home from './page/Home'
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Route } from 'react-router-dom';
import './static/css/bootstrap.min.css'
import './static/css/general.css'
import {PageView, init} from './components/TrackingAnalytics';

function initTrackingAnalytics() {
    init('UA-135922657-1');
    PageView("/");
}

ReactDOM.render(
    <BrowserRouter>
        <div>
            <Route exact path="/" component={Home} />
            <Route path='/web/social/facebook' component={() => { PageView("/social/facebook"); window.location = 'https://www.facebook.com/YafterOficial'; return null;} }/>
            <Route path='/web/social/instagram' component={() => { PageView("/social/instagram"); window.location = 'https://www.instagram.com/yafterOficial/'; return null;} }/>
            <Route path='/web/social/twitter' component={() => { PageView("/social/twitter"); window.location = 'http://www.twitter.com/YafterOficial'; return null;} }/>
            <Route path='/web/download/android' component={() => {PageView("/download/android"); window.location = 'https://play.google.com/store/apps/details?id=com.yafter'; return null;}}/>
            <Route path='/web/download/ios' component={() => {PageView("/download/ios"); window.location = 'https://itunes.apple.com/us/app/yafter/id1456059255'; return null;}}/>
        </div>
    </BrowserRouter>,
    document.getElementById('root')
);

serviceWorker.unregister();
initTrackingAnalytics();
