import React from 'react'
import mobile from '../../static/img/template/mobile5.png'

const PresentationOne = () => (

    <div className="section section-presentation">
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <div className="description">
                        <h4 className="header-text">CREA</h4>
                        <p style={{textAlign: 'justify'}}>Podes crear tus propios after en los que se pueden sumar amigos,
                            colegas o hasta gente nueva para conocer. Con completar un nombre,
                            descripción, dirección y horario ya estas mostrando a la comunidad
                            tus ganas de ir de after.
                        </p>
                    </div>
                </div>
                <div className="col-md-4 col-md-offset-2 hidden-xs">
                    <img src={mobile} id="mobile5" alt="mobile5"/>
                </div>
            </div>
        </div>
    </div>
);

export default PresentationOne