import React from 'react'
import mobile from '../../static/img/template/mobile3.png'

const PresentationOne = () => (

    <div className="section section-presentation">
        <div className="container">
            <div className="row">
                <div className="col-md-6 hidden-xs">
                    <img src={mobile} alt="mobile"/>
                </div>
                <div className="col-md-6 " >

                    <div className="description">
                        <h4 className="header-text">EXPLORA</h4>
                        <p style={{textAlign: 'justify'}}>Podes ver la lista de afters que están por venir detalladamente como también un mapa
                            accesible donde te marca todos los after por venir por zona geográfica. ¡En un par de
                            segundos ya podes organizar a donde se sale la próxima vez!
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div>
);

export default PresentationOne