import React from 'react'
import bg3 from '../../static/img/template/bg3.jpg'
import {Link} from 'react-router-dom'
import playStore from '../../static/img/logos/download-play-store.png'
import appSotre from '../../static/img/logos/download-app-store.png'


const Download = () => (
    <div className="section section-no-padding">
        <div className="parallax filter-gradient blue" data-color="blue">
            <div className="parallax-background">
                <img className="parallax-background-image" src={bg3} alt="bg3"/>
            </div>
            <div className="info">
                <h1>¡DESCARGA YAFTER PARA ANDROID y iOS!</h1>

                <div className="buttons">
                    <Link to="/web/download/android" target='_blank'>
                        <img src={playStore} alt="playStore" style={{maxWidth: 280, maxHeight: 280}}/>
                    </Link>
                </div>

                <div className="buttons">
                    <Link to="/web/download/ios" target='_blank'>
                        <img src={appSotre} alt="appStore" style={{maxWidth: 280, maxHeight: 280}}/>
                    </Link>
                </div>


            </div>
        </div>
    </div>
);

export default Download
