import React from 'react'
import {Link} from 'react-router-dom'

const Footer = () => (
        <footer className="footer">
            <div className="container">

                <div className="social-area pull-left">
                    <div className="copyright">&copy; 2019 <a href="https://www.yafter.com">yafter.com</a></div>

                </div>

                <div className="social-area pull-right">
                    <Link to="/web/social/facebook" target='_blank'>
                        <div className="btn btn-social btn-facebook btn-simple"><i className="fa fa-facebook-square"/></div>
                    </Link>
                    <Link to="/web/social/twitter" target='_blank'>
                        <div className="btn btn-social btn-twitter btn-simple"><i className="fa fa-twitter"/></div>
                    </Link>
                    <Link to="/web/social/instagram" target='_blank'>
                        <div className="btn btn-social btn-pinterest btn-simple"><i className="fa fa-instagram"/></div>
                    </Link>
                </div>


            </div>
        </footer>
    )
;

export default Footer
