import ReactGA from 'react-ga';

export const init = (trackingID) => {
    ReactGA.initialize(trackingID);
};

export const PageView = (page) => {
    ReactGA.pageview(page);
};

export const Event = (category, action, label) => {
    ReactGA.event ({
        category: category,
        action: action,
        label: label
    });
};
