import React from 'react'
import {Link} from "react-router-dom";

import twitter from '../../static/img/logos/twitter1.png';
import facebook from '../../static/img/logos/facebook1.png';
import instragram from '../../static/img/logos/instagram1.png';

const Social = () => (

    <div className="section section-gray section-clients">
        <div className="container text-center">
            <h4 className="header-text">Seguinos en nuestras redes sociales</h4>
            <p> ¡Porque lo importante sos vos!, podes comunicarte con el staff de
                <br/>
                yafter como también entérate de las últimas novedades a través de
                <br/>
                Facebook, Instagram y Twitter.
                <br/>
            </p>
            <div className="logos">
                <ul>
                    <div className="row">
                        <div className="col-md-4">
                            <li>
                                <Link to='/web/social/facebook' target='_blank'>
                                    <img src={facebook} alt="facebook" className="social-img"/>
                                </Link>

                            </li>
                        </div>

                        <div className="col-md-4">
                            <li>
                                <Link to="/web/social/twitter" target='_blank'>
                                    <img src={twitter} alt="twitter" className="social-img"/>
                                </Link>
                            </li>
                        </div>

                        <div className="col-md-4">
                            <li>
                                <Link to="/web/social/instagram" target='_blank'>
                                    <img src={instragram} alt="instragram" className="social-img"/>
                                </Link>
                            </li>
                        </div>

                    </div>
                </ul>
            </div>
        </div>
    </div>

);

export default Social