import React from 'react'

const Feactures = () => (
    <div className="section section-features">
        <div className="container">
            <h4 className="header-text text-center">caracteristicas destacables</h4>
            <div className="row">
                <div className="col-md-4">
                    <div className="card card-blue">
                        <div className="icon">
                            <i className="fa fa-map-marker"/>
                        </div>
                        <h4>Mapa de afters</h4>
                        <p>Te mostramos en un mapa todos los after por venir con el fin que puedas el after más
                            cercano a tu posición
                        </p>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card card-blue">
                        <div className="icon">
                            <i className="fa fa-beer"/>
                        </div>
                        <div className="text">
                            <h4>Opciones para salir</h4>
                            <p>Con yafter no te quedas más en casa, siempre tienes una opción para salir cuando
                                quieras, o podes empezar a organizar tus propias salidas
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card card-blue">
                        <div className="icon">
                            <i className="fa fa-comments"/>
                        </div>
                        <h4>Chat global</h4>
                        <p>Podes chatear en tiempo real con las personas conectadas a la app accediendo desde
                            el menú principal
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Feactures