import React from 'react'

import '../static/css/home.css'

import Nav from '../components/home/Nav'
import Intro from '../components/home/Intro'
import Social from '../components/home/Social'
import PresentationOne from '../components/home/PresentationOne'
import PresentationTwo from '../components/home/PresentationTwo'
import PresentationThree from '../components/home/PresentationThree'
import Feactures from '../components/home/Feactures'
import Download from '../components/home/Download'
import Footer from '../components/home/Footer'

const Home = () => (

    <div>
        <div className="landing-page landing-page1">
        <Nav/>
        <div className="wrapper">
            <Intro/>
            <Social/>
            <PresentationOne/>
            <PresentationTwo/>
            <PresentationThree/>
            <Feactures/>
            <Download/>
            <Footer/>
        </div>
        </div>
    </div>
);

export default Home