import React from 'react'
import {Link} from 'react-router-dom'

const HomeHeader = () => (

    <nav className="navbar navbar-transparent navbar-top hidden-xs" role="navigation">
        <div className="container">

            <div className="collapse navbar-collapse" id="example">
                <ul className="nav navbar-nav navbar-right">
                    <li>
                        <Link to="/web/social/facebook" target='_blank'>
                            <i className="fa fa-facebook-square"/> Facebook
                        </Link>
                    </li>
                    <li>
                        <Link to="/web/social/instagram" target='_blank'>
                            <i className="fa fa-instagram"/> Instagram
                        </Link>
                    </li>
                    <li>
                        <Link to="/web/social/twitter" target='_blank'>
                            <i className="fa fa-twitter"/> Twitter
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

);

export default HomeHeader
