import React from 'react'
import mobile from '../../static/img/template/mobile4.png'

const PresentationThree = () => (

    <div className="section section-presentation">
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <div className="description">
                        <h4 className="header-text">UNETE</h4>
                        <p style={{textAlign: 'justify'}}>Te brindamos la información necesaria del after al que quieres concurrir, como fecha,
                            horario, descripción, creador y listado de personas de las cuales van a participar.
                            También tienes disponible un chat para que puedas comunicarte con los participantes
                            y puedan coordinar antes del inicio del after.
                        </p>
                    </div>
                </div>
                <div className="col-md-4 col-md-offset-2 hidden-xs">
                    <img src={mobile} alt="mac"/>
                </div>
            </div>
        </div>
    </div>
);

export default PresentationThree