import React from 'react'
import bg3 from '../../static/img/template/bg3.png';
import mobile1 from '../../static/img/template/mobile1.png'
import {Link} from 'react-router-dom'

import playStore from '../../static/img/logos/download-play-store.png'
import appStore from '../../static/img/logos/download-app-store.png'

import yafter from '../../static/img/logos/yafter.png'

const description = 'Con Yafter podrás crear tus propios after office y sumarte a los after de otras personas! ' +
    'Conoce nuevas personas y lugares. Diviértete,  salí,  aventúrate...';

const title = '¡Salgamos de fiesta!';

const ButtonDownloadAndroid = () => (
    <div className="buttons">
        <Link to="/web/download/android" target='_blank'>
            <img src={playStore} alt="playStore" style={{maxWidth: 160, maxHeight: 160}}/>
        </Link>
    </div>
);

const ButtonDownloadIOS = ({buttonIOS}) => (
    <div className="buttons">
        <Link to="/web/download/ios" target='_blank'>
            {buttonIOS}
        </Link>
    </div>
);

const ButtonIOSMobile = () => (
    <img src={appStore} alt="playStore" style={{maxWidth: 160, maxHeight: 160, marginTop: 15}}/>
);
const ButtonIOSWeb = () => (
    <img src={appStore} alt="playStore" style={{maxWidth: 160, maxHeight: 160}}/>
);



const ContentWeb = () => (
    <div className="row hidden-xs">
        <div className="col-md-5">
            <div className="parallax-image">
                <img className="phone" src={mobile1}
                     style={{marginTop: 20}} alt="mobileYafter"/>
            </div>
        </div>
        <div className="col-md-6 col-md-offset-1">
            <div className="description">
                <h2 className="font-exo2" style={{fontSize: 45, fontWeight: 700}}>{title}</h2>
                <br/>
                <h5 className="font-exo2" style={{fontSize: 25, fontWeight: 700}}>{description}</h5>
            </div>
            <div className="row">
                <div className="col-md-5">
                    <ButtonDownloadAndroid/>
                </div>
                <div className="col-md-6">
                    <ButtonDownloadIOS buttonIOS={<ButtonIOSWeb/>}/>
                </div>
            </div>
        </div>
    </div>
);

const ContentMobile = () => (
    <div className="row visible-xs">
        <div className="col-md-6 col-md-offset-1 ">
            <div className="description">
                <img src={yafter} alt="yafterLogo" style={{maxWidth: 200, maxHeight: 200, marginBottom: -15, marginTop: -30}}/>
                <br/>
                <br/>
                <h5 className="font-exo2" style={{fontSize: 25, fontWeight: 500}}>{description}</h5>
            </div>
            <ButtonDownloadAndroid/>
            <ButtonDownloadIOS buttonIOS={<ButtonIOSMobile/>}/>
        </div>
    </div>
);

const HomeBodyOne = () => (
    <div className="parallax filter-gradient blue" data-color="blue">
        <div className="parallax-background">
            <img className="parallax-background-image" src={bg3} alt="background"/>
        </div>

        <div className="container">
            <ContentWeb/>
            <ContentMobile/>
        </div>
    </div>
);

export default HomeBodyOne
